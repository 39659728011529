import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, concatMap, exhaustMap, filter, interval, map, of, withLatestFrom } from "rxjs";
import { ExchangeService } from "../services/exchange.service";
import { ReferenceService } from "../services/reference.service";
import { UserService } from "../services/user.service";
import { SharedActions } from "./shared-action-types";
import { SharedState } from "./shared-state-interface";
import { NotificationItem } from "../models/notification.model";
import { getAccountCurrenciesLoaded, getCompanyAccountCurrenciesLoaded, getCompanySettingsLoaded, getCompanyVirtualAccountsLoaded, getCustomerRoleTypesLoaded, getNotifications, getOfficesLoaded, getRoleTypesLoaded, getTransactionypesLoaded, selectCustomers, selectSuppliers } from "./shared.selectors";
import { NotificationService } from "../services/notification.service";
import { Customer, CustomerAddress } from "../models/customer.model";
import { PersonService } from "../services/person.service";
import { Person } from "../models/person.model";
import { ConciliationService } from '../services/conciliation.service';
import { SettingsService } from "../services/settings.service";
import { ExpensesService } from "../services/expenses.service";
import { BankAccount } from "../models/bank-account.model";

@Injectable()
export class SharedEffects {

  loadExchanges$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadAllExchanges),
    concatMap(() => this.exchandgeService.getAllExchanges()),
    map(exchanges => SharedActions.allExchangesLoaded({ exchanges }))
  ));

  loadExchangeTypes$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadExchangeTypeReference),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const referenceState = ((state[1] as any)?.sharedContext as SharedState).referenceState;
      if (!referenceState?.exchangeTypesLoaded) {
        return this.referenceService.getExchangeTypes();
      }
      else {
        return of(referenceState?.exchangeTypes!);
      }
    }),
    map(exchangeTypes => SharedActions.exchangeTypeReferenceLoaded({ exchangeTypes }))
  ));


  loadRoleTypeReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadRoleTypeReference),
    withLatestFrom(this.store.select(getRoleTypesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getRoles();
    }),
    map(roles => SharedActions.loadRoleTypeReferenceLoaded({ roles }))
  ));


  loadTransactionTypeReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadTransactionTypeReference),
    withLatestFrom(this.store.select(getTransactionypesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getTransactionTypes();
    }),
    map(data => SharedActions.loadTransactionTypeReferenceLoaded({ data }))
  ));

  reloadTransactionTypeReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.reloadTransactionTypeReference),
    concatMap(() => {
      return this.referenceService.getTransactionTypes();
    }),
    map(data => SharedActions.loadTransactionTypeReferenceLoaded({ data }))
  ));

  loadAccountCurrenciesReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadAccountCurrenciesReference),
    withLatestFrom(this.store.select(getAccountCurrenciesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getAccountCurrencies();
    }),
    map(data => SharedActions.loadAccountCurrenciesReferenceLoaded({ data }))
  ));

  loadCompanyAccountCurrenciesReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadCompanyAccountCurrenciesReference),
    withLatestFrom(this.store.select(getCompanyAccountCurrenciesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getCompanyAccountCurrencies();
    }),
    map(data => SharedActions.loadCompanyAccountCurrenciesReferenceLoaded({ data }))
  ));

  reloadAccountCurrenciesReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.reloadAccountCurrenciesReference),
    concatMap(() => {
      return this.referenceService.getAccountCurrencies();
    }),
    map(data => SharedActions.loadAccountCurrenciesReferenceLoaded({ data }))
  ));

  loadCustomerRolesReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadCustomerRolesReference),
    withLatestFrom(this.store.select(getCustomerRoleTypesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getCustomerRoles();
    }),
    map(roles => SharedActions.loadCustomerRolesLoaded({ roles }))
  ));

  loadOrderCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadOrderCurrencyReference),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const referenceState = ((state[1] as any)?.sharedContext as SharedState).referenceState;
      if (!referenceState?.orderCurrenciesLoaded) {
        return this.referenceService.getOrderCurrency();
      }
      else {
        return of(referenceState?.orderCurrencies!);
      }
    }),
    map(orderCurrencies => SharedActions.orderCurrencyReferenceLoaded({ orderCurrencies }))
  ));

  loadOrderCreateCurrencyReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadOrderCreateCurrencyReference),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const referenceState = ((state[1] as any)?.sharedContext as SharedState).referenceState;
      if (!referenceState?.orderCreateCurrenciesLoaded) {
        return this.referenceService.getOrderCreateCurrency();
      }
      else {
        return of(referenceState?.orderCreateCurrencies!);
      }
    }),
    map(orderCurrencies => SharedActions.orderCreateCurrencyReferenceLoaded({ orderCurrencies }))
  ));

  loadOrderTransactionTypesReference$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadOrderTransactionTypesReference),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const referenceState = ((state[1] as any)?.sharedContext as SharedState).referenceState;
      if (!referenceState?.OrderTransactionTypesLoaded) {
        return this.referenceService.getOrderTransactionTypes();
      }
      else {
        return of(referenceState?.OrderTransactionTypes!);
      }
    }),
    map(data => SharedActions.OrderTransactionTypesReferenceLoaded({ data }))
  ));

  loadWorkTrays$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadworkTrayReference),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const referenceState = ((state[1] as any)?.sharedContext as SharedState).referenceState;
      if (!referenceState?.workTraysLoaded) {
        return this.referenceService.getWorkTray();
      }
      else {
        return of(referenceState?.workTrays!);
      }
    }),
    map(workTrays => SharedActions.workTrayReferenceLoaded({ workTrays: workTrays }))
  ));

  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.searchUsers),
    withLatestFrom(this.store),
    exhaustMap((state) => {
      const userState = ((state[1] as any)?.sharedContext as SharedState).userState;
      return this.userService.getUsers(userState?.filters);
    }),
    map(users => SharedActions.allUsersLoaded({ users }))
  ));

  getNotificationPeriodically$ = createEffect(() => {
    return interval(1000 * 300).pipe(
      withLatestFrom(this.store),
      filter(state => !!state && !!(state[1] as any).auth?.user),
      map(() => {
        // Fire Load notification if the user is logged
        return SharedActions.loadNotifications();
      }),
    )
  });


  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.loadNotifications),
      withLatestFrom(this.store),
      exhaustMap((state) => {
        return this.notificationSvc.findAll();
      }),
      map(notifications => SharedActions.notificationLoaded({ notifications }))
    );
  });

  deleteNotification$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(SharedActions.deleteNotification),
      withLatestFrom(this.store.select(getNotifications)),
      exhaustMap((action) => {
        const notifications = action[1];
        const filteredList: NotificationItem[] = notifications.filter(n => n.id !== action[0].id);
        return of(filteredList);
      }),
      map(notifications => SharedActions.notificationLoaded({ notifications }))
    );
  });

  deleteNotificationAPI$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(SharedActions.deleteNotification),
      exhaustMap((action) => {
        // call API to remove item
        return this.notificationSvc.delete(action.id);
      }),
      map(() => SharedActions.notificationDeleted())
    );
  });

  loadCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.loadCustomers),
      withLatestFrom(this.store.select(selectCustomers)),
      exhaustMap((values) => {
        return this.PersonSvc.search('customer', undefined, undefined, undefined, undefined, undefined, values[1]?.lastSearchedDate?.toISOString()).pipe(
          map(data => {
            const customer: Customer[] = data
              .map(d => new Customer(
                d.id,
                d.name,
                d.phoneNumber,
                'addresses' in d ? <CustomerAddress[] | undefined>d.addresses : undefined,
                'isUser' in d ? <boolean | undefined>d.isUser : undefined,
                'mail' in d ? <string | undefined>d.mail : undefined,
                undefined,
                d.accountId,
                'bankAccounts' in d ? <BankAccount[] | undefined>d.bankAccounts : undefined,
              ));
            const origin = values[1]?.customers ?? [];
            return SharedActions.loadCustomersSuccess({ customers: this.mergeCustomers(origin, customer) });
          }),
          catchError(error => of(SharedActions.loadCustomersFailure({ error }))))
      }
      )
    );
  });

  loadSuppliers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.loadSuppliers),
      withLatestFrom(this.store.select(selectSuppliers)),
      exhaustMap((values) => {
        return this.PersonSvc.search('supplier', undefined, undefined, undefined, undefined, undefined, values[1]?.lastSearchedDate?.toISOString()).pipe(
          map(data => {
            const customer: Person[] = data;
            const origin = values[1]?.suppliers ?? [];
            return SharedActions.loadSuppliersSuccess({ suppliers: this.mergePerson(origin, customer) });
          }),
          catchError(error => of(SharedActions.loadCustomersFailure({ error }))))
      }
      )
    );
  });


  getCompanySettings$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadCompanySettings),
    withLatestFrom(this.store.select(getCompanySettingsLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.settingSvc.getCompanySettings();
    }),
    map(data => {
      localStorage.setItem('companySettings', JSON.stringify(data));
      return SharedActions.loadCompanySettingsLoaded({ data });
    })
  ));

  loadReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.loadReasons),
      withLatestFrom(this.store),
      exhaustMap((state) => {
        return this.ExpensesSvc.findReasons();
      }),
      map(expenseReasons => SharedActions.loadReasonsSuccess({ expenseReasons })
      ),
      catchError(error => of(SharedActions.loadReasonsFailure({ error }))))
  });

  loadCompanyVirtualAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadCompanyVirtualAccount),
    withLatestFrom(this.store.select(getCompanyVirtualAccountsLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getCompanyVirtualAccounts();
    }),
    map(accounts => SharedActions.loadCompanyVirtualAccountSuccess({ accounts }))
  ));

  loadCompanyOffices$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.loadCompanyOffices),
    withLatestFrom(this.store.select(getOfficesLoaded)),
    filter(loaded => !loaded[1]),
    exhaustMap(() => {
      return this.referenceService.getCompanyOffices();
    }),
    map(data => SharedActions.companyOfficesLoaded({ data }))
  ));

  constructor(
    private actions$: Actions,
    private exchandgeService: ExchangeService,
    private referenceService: ReferenceService,
    private userService: UserService,
    private notificationSvc: NotificationService,
    private PersonSvc: PersonService,
    private ExpensesSvc: ExpensesService,
    private store: Store,
    private conciliationService: ConciliationService,
    private settingSvc: SettingsService,
  ) { }

  private mergeCustomers(origin: Customer[], delta: Customer[]): Customer[] {
    // Si no viene nada en el delta vuelve el original
    if (delta.length < 1) {
      return origin;
    }

    // Si no tenia un original y viene en el delta vuelve el delta
    if (origin.length < 1 && delta.length > 0) {
      return delta;
    }

    // Crear un nuevo array reemplazando los elementos de origin con los de delta basándose en el ID
    const mergedArray: Customer[] = origin.map(customer1 =>
      delta.find(customer2 => customer2.id === customer1.id) || customer1
    );

    // Obtener elementos de delta que no están en origin
    const newItems: Customer[] = delta.filter(customer2 =>
      !origin.some(customer1 => customer1.id === customer2.id)
    );
    return Object.assign(mergedArray, newItems);
  }

  private mergePerson(origin: Person[], delta: Person[]): Person[] {
    // Si no viene nada en el delta vuelve el original
    if (delta.length < 1) {
      return origin;
    }

    // Si no tenia un original y viene en el delta vuelve el delta
    if (origin.length < 1 && delta.length > 0) {
      return delta;
    }

    // Crear un nuevo array reemplazando los elementos de origin con los de delta basándose en el ID
    const mergedArray: Person[] = origin.map(customer1 =>
      delta.find(customer2 => customer2.id === customer1.id) || customer1
    );

    // Obtener elementos de delta que no están en origin
    const newItems: Person[] = delta.filter(customer2 =>
      !origin.some(customer1 => customer1.id === customer2.id)
    );
    return Object.assign(mergedArray, newItems);
  }
}
