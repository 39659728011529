import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortCurrency'
})
export class ShortCurrencyPipe implements PipeTransform {
  transform(value: number, currency: string): string {
    if (value < 1000) {
      return `${currency} ${value}`;
    } else if (value < 1000000) {
      return `${currency} ${Math.ceil(value / 1000)}k`;
    } else {
      return `${currency} ${Math.ceil(value / 1000000)}m`;
    }
  }
}