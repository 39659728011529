import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { NotificationItem } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  constructor(
    private http: HttpClient) {
    super(http);
  }

  findAll() {
    return super.get<NotificationItem[]>('notification/find');
  }

  delete(id: string) {
    return super.deleteMethod(`notification/${id}`);
  }
}
