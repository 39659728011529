import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { CompanySettings } from '../models/settings/company-settings.model';
import { OrderSettings } from '../models/settings/order-settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
   }

  order(settings: OrderSettings) {
    return super.patch(`Settings/Company/Order`, settings);
  }

  getOrder() {
    return super.get<OrderSettings>(`Settings/Company/Order`);
  }

  getCompanySettings() {
    return super.get<CompanySettings>(`Settings/Company`);
  }
}
