import { createReducer, on } from "@ngrx/store";
import { SharedActions } from "./shared-action-types";
import { ExchangeState, SharedState } from "./shared-state-interface";
import { AuthActions } from "src/app/auth/action-types";

export const sharedFeatureKey = "shared";

export const initialState: SharedState = {
  exchangesState: undefined,
};

export const sharedReducer = createReducer(
  initialState,


  // Si se desloguea tengo que limpiar todo!
  on(AuthActions.logout, state => initialState),


  on(SharedActions.allExchangesLoaded, (state, { exchanges }) => ({
    ...state,
    exchangesState: <ExchangeState>{
      exchanges: exchanges,
      loaded: true,
    },
  })),

  on(SharedActions.exchangeTypeReferenceLoaded, (state, { exchangeTypes }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      roleTypes: state.referenceState?.roleTypes ?? undefined,
      roleTypesLoaded: state.referenceState?.roleTypesLoaded ?? false,
      exchangeTypes: exchangeTypes,
      exchangeTypesLoaded: true,
      orderCurrencies: state.referenceState?.orderCurrencies ?? undefined,
      orderCurrenciesLoaded: state.referenceState?.orderCurrenciesLoaded ?? false,
      workTrays: state.referenceState?.workTrays ?? undefined,
      workTraysLoaded: state.referenceState?.workTraysLoaded ?? false,
    },
  })),

  on(SharedActions.loadRoleTypeReferenceLoaded, (state, { roles }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      roleTypes: roles,
      roleTypesLoaded: true,
    },
  })),

  on(SharedActions.loadTransactionTypeReferenceLoaded, (state, { data }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      transactionTypes: data,
      transactionTypesLoaded: true,
    },
  })),

  on(SharedActions.loadAccountCurrenciesReferenceLoaded, (state, { data }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      accountCurrencies: data,
      accountCurrenciesLoaded: true,
    },
  })),

  on(SharedActions.loadCompanyAccountCurrenciesReferenceLoaded, (state, { data }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      companyAccountCurrencies: data,
      companyAccountCurrenciesLoaded: true,
    },
  })),

  on(SharedActions.orderCurrencyReferenceLoaded, (state, { orderCurrencies }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      roleTypes: state.referenceState?.roleTypes ?? undefined,
      roleTypesLoaded: state.referenceState?.roleTypesLoaded ?? false,
      exchangeTypes: state.referenceState?.exchangeTypes ?? undefined,
      exchangeTypesLoaded: state.referenceState?.exchangeTypesLoaded ?? false,
      orderCurrencies: orderCurrencies,
      orderCurrenciesLoaded: true,
      workTrays: state.referenceState?.workTrays ?? undefined,
      workTraysLoaded: state.referenceState?.workTraysLoaded ?? false,
    },
  })),

  on(SharedActions.workTrayReferenceLoaded, (state, { workTrays }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      roleTypes: state.referenceState?.roleTypes ?? undefined,
      roleTypesLoaded: state.referenceState?.roleTypesLoaded ?? false,
      exchangeTypes: state.referenceState?.exchangeTypes ?? undefined,
      exchangeTypesLoaded: state.referenceState?.exchangeTypesLoaded ?? false,
      orderCurrencies: state.referenceState?.orderCurrencies ?? undefined,
      orderCurrenciesLoaded: state.referenceState?.orderCurrenciesLoaded ?? false,
      workTrays: workTrays,
      workTraysLoaded: true,
    },
  })),

  on(SharedActions.resetWorkTrayReference, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      roleTypes: state.referenceState?.roleTypes ?? undefined,
      roleTypesLoaded: state.referenceState?.roleTypesLoaded ?? false,
      exchangeTypes: state.referenceState?.exchangeTypes ?? undefined,
      exchangeTypesLoaded: state.referenceState?.exchangeTypesLoaded ?? false,
      orderCurrencies: state.referenceState?.orderCurrencies ?? undefined,
      orderCurrenciesLoaded: state.referenceState?.orderCurrenciesLoaded ?? false,
      workTrays: undefined,
      workTraysLoaded: false,
    },
  })),

  on(SharedActions.addFilterUsers, (state, { filters }) => ({
    ...state,
    userState: {
      users: state.userState?.users,
      loaded: state.userState?.loaded ?? false,
      filters: filters,
    },
  })),

  on(SharedActions.loadCustomerRolesLoaded, (state, { roles }) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      customerRoles: roles,
      customerRolesLoaded: true
    }
  })),

  on(SharedActions.allUsersLoaded, (state, { users }) => ({
    ...state,
    userState: {
      users: users,
      loaded: true,
      filters: state.userState?.filters,
    },
  })),

  on(SharedActions.startHelpTour, (state, { component }) => ({
    ...state,
    helpTourCompnent: component,
  })),

  on(SharedActions.finishHelpTour, (state, action) => ({
    ...state,
    helpTourCompnent: undefined,
  })),

  on(SharedActions.setworkInProgressFlag, (state, action) => ({
    ...state,
    workInProgress: action.workInProgress,
  })),

  // Notifications
  on(SharedActions.loadNotifications, (state, action) => ({
    ...state,
    notifications: {
      ...state.notifications,
      notifications: {
        ...state.notifications?.notifications?.entity,
        loaded: false,
        error: undefined,
      },
    },
  })),
  on(SharedActions.notificationLoaded, (state, action) => ({
    ...state,
    notifications: {
      ...state.notifications,
      notifications: {
        entity: action.notifications,
        loaded: true,
        error: undefined,
      },
      notificationCount: action.notifications?.length ?? 0,
    },
  })),
  on(SharedActions.deleteNotification, (state, action) => ({
    ...state,
    notifications: {
      ...state.notifications,
      notifications: {
        ...state.notifications.notifications,
        loaded: false,
      },
    },
  })),

  // Customers
  on(SharedActions.loadCustomersSuccess, (state, action) => ({
    ...state,
    customers: {
      customers: action.customers,
      lastSearchedDate: new Date(),
    },
  })),

  // Suppliers
  on(SharedActions.loadSuppliersSuccess, (state, action) => ({
    ...state,
    suppliers: {
      suppliers: action.suppliers,
      lastSearchedDate: new Date(),
    },
  })),

  // Order Transaction Types
  on(SharedActions.OrderTransactionTypesReferenceLoaded, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      OrderTransactionTypes: action.data,
      OrderTransactionTypesLoaded: true
    }
  })),

  // Order Create Currency
  on(SharedActions.orderCreateCurrencyReferenceLoaded, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      orderCreateCurrencies: action.orderCurrencies,
      orderCreateCurrenciesLoaded: true,
    }
  })),
  on(SharedActions.forceLoadOrderCreateCurrencyReference, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      orderCreateCurrenciesLoaded: false,
    }
  })),

  // Company Settings
  on(SharedActions.loadCompanySettingsLoaded, (state, action) => ({
    ...state,
    comapnySettings: {
      comapnySettingsLoaded: true,
      comapnySettings: action.data
    }
  })),
  on(SharedActions.clearCompanySettings, (state, action) => ({
    ...state,
    comapnySettings: initialState.comapnySettings
  })),

  // Expenses
  on(SharedActions.loadReasonsSuccess, (state, action) => ({
    ...state,
    reasons: {
      reasons: action.expenseReasons,
      lastSearchedDate: new Date(),
    },
  })),

  // Company Virtual Accounts
  on(SharedActions.loadCompanyVirtualAccountSuccess, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      companyVirtualAccounts: action.accounts,
      companyVirtualAccountsLoaded: true
    },
  })),

  on(SharedActions.reloadCompanyVirtualAccount, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      companyVirtualAccountsLoaded: false
    },
  })),

  on(SharedActions.companyOfficesLoaded, (state, action) => ({
    ...state,
    referenceState: {
      ...state.referenceState,
      offices: action.data,
      officesLoaded: true
    },
  })),

);
