import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'auditTranslate'
})
export class AuditTranslatePipe implements PipeTransform {

  translateJson = {
    "SuccessAttempt": "Login Exitoso",
    "FailedAttempt": "Login Fallido",
    "ResetPassword": "Restablecer Contraseña",
    "ResetPasswordFailed": "Restablecimiento de Contraseña Fallido",
    "Create": "Crear",
    "Edit": "Editar",
    "GetDetail": "Obtener Detalle",
    "Delete": "Eliminar",
    "Find": "Buscar",
    "Disabled": "Deshabilitar",
    "Enabled": "Habilitar",
    "Dashboard": "Ver Tablero",
    "ReportDelay": "Reportar Retraso",
    "Cancel": "Cancelar",
    "Delivered": "Entregado",
    "DeliveryMessage": "Mensaje de Entrega",
    "Continue": "Continuar",
    "DeleteFile": "Eliminar Archivo",
    "UploadFile": "Subir Archivo",
    "DonwloadFile": "Descargar Archivo",
    "SetInitailValues": "Establecer Valores Iniciales",
    "Checked": "Verificado",
    "OnSystem": "En el Sistema",
    "Set": "Establecer",
    "SetTelegram": "Configurar Telegram",
    "GrantAccess": "Conceder Acceso",
    "Settings": "Configuraciones",
    "Accesses": "Accesos",
    "Users": "Usuarios",
    "Order": "Orden",
    "Cash" : "Caja dinámica",
    "Product" : "Producto",
    "Exchange" : "Tipo cambio",
    "Delivery" : "Cadete",
    "Customer" : "Cliente",
    "Supplier" : "Proveedor",
    "Company" : "Compañía"
  };

  transform(value: string): string {
    return this.translateJson[value] ?? value;
  }
}
