import { Pipe, PipeTransform } from '@angular/core';
import { FormatTemporality } from '../models/enums/enums.model';
import { DatePipe } from '@angular/common';

export interface DiffDate {
  day: number;
  hour: number;
  minute: number;
  second: number
}

@Pipe({
  name: 'temporality'
})
export class TemporalityPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: Date, format?: FormatTemporality): unknown {
    const now = new Date();
    const valueDate = new Date(value);

    const diff = this.getDataDiff(valueDate, now);

    if (!format) {
      format = FormatTemporality.MinutesAndHours;
    }

    switch (format) {
      case FormatTemporality.MinutesAndHours:
        return this.formatMinutesAndHours(diff, valueDate);

        case FormatTemporality.DateAndTime:
        return this.formatDateAndTime(diff, valueDate);

      default:
        break;
    }


    return valueDate.toLocaleTimeString();
  }

  private formatMinutesAndHours(diff: DiffDate, originalValue: Date) : string | undefined {
    if (diff.hour < 1) {
      // Menos de una hora
      if (diff.minute < 15)
        return 'ahora';
      else if (diff.minute >= 15 && diff.minute < 30)
        return 'hace 15 minutos';
      else if (diff.minute >= 30 && diff.minute < 45)
        return 'hace 30 minutos';
      else if (diff.minute >= 45)
        return 'hace 45 minutos';
    } else if (diff.hour === 1) {
      return 'hace 1 hora';
    } else if (diff.hour > 1) {
      let  horas = diff.hour;
      if (diff.day > 0) {
        horas = horas + (diff.day * 24);
      }
      return `hace ${horas} horas`;
    }

    return originalValue.toLocaleTimeString();
  }

  private formatDateAndTime(diff: DiffDate, originalValue: Date) : string | undefined {
    if (diff.day > 1) {
      return `${this.datePipe.transform(originalValue, 'd/M/yy HH:mm')} hrs`;
    } else {
      return `a las ${this.datePipe.transform(originalValue, 'HH:mm')} hrs`;
    }
  }


  private getDataDiff(startDate: Date, endDate: Date): DiffDate {
    var diff = endDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return { day: days, hour: hours, minute: minutes, second: seconds };
}

}
