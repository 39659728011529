import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuditTranslatePipe } from './pipes/audit-translate.pipe';
import { ShortCurrencyPipe } from './pipes/short-currency.pipe';
import { TemporalityPipe } from './pipes/temporality.pipe';
import { SharedEffects } from './state/shared.effects';
import { sharedReducer } from './state/shared.reducer';

@NgModule({
  declarations: [
    TemporalityPipe,
    AuditTranslatePipe,
    ShortCurrencyPipe
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('sharedContext', sharedReducer),
    EffectsModule.forFeature([SharedEffects]),
  ],
  exports: [TemporalityPipe, AuditTranslatePipe, ShortCurrencyPipe],
  providers: [
    DatePipe,
    DecimalPipe
  ]
})
export class SharedModule { }
